export const environment = {
    production: true,
    name: 'test',
    BACKEND : 'https://api-am-test.standardc.io/',
    FRONTEND : 'https://am-test.standardc.io/',
    //IDme Client-id
    client_id : 'c52c48be3de22fa1c8c073f9b7cbeda8',
    idme_scope: "identity_test",
    idme_url : 'https://api.idmelabs.com',
    idme_redirect_uri : 'https://am-test.standardc.io/verification',
    reCaptchaSiteKey : '6Le69pUUAAAAAA9kamHDw_xKWiEcJq3-3noH97Dl'
  };